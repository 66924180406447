import React from "react";

type Props = {
  title: string;
};

function Or({ title }: Props) {
  return (
    <div className="login-social-title">
      <h6>{title} </h6>
    </div>
  );
}

export default Or;
