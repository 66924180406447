import React from "react";

type Props = {
  name: string;
};

function Brand({ name }: Props) {
  return (
    <a className="logo" href="index.html">
      <h3>{name}</h3>
      {/* <img
                    className="img-fluid for-light"
                    src="../assets/images/logo/logo2.png"
                    alt="looginpage"
                  /> */}
    </a>
  );
}

export default Brand;
