import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    productName: "",
  },
  reducers: {
    updateProductName: (state, action) => {
      state.productName = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { updateProductName } = userSlice.actions;
export default userSlice.reducer;
