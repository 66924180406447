import React from "react";
import Signin from "./components/signin/Signin";
import { useSelector } from "react-redux";
import Signup from "./components/signup/Signup";
import FogotPassword from "./components/forgot/FogotPassword";
import Brand from "./components/brand/Brand";

function App() {
  let page = useSelector((state: any) => state.app.page);

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-12 p-0">
          <div className="login-card">
            <div>
              <div>
                <Brand name="BrandNameHere" />
              </div>
              {page === "signin" && <Signin />}
              {page === "signup" && <Signup />}
              {page === "forgotpassword" && <FogotPassword />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
