import React from "react";

type Props = {
  title: string;
};

function Button({ title }: Props) {
  return (
    <div className="text-end mt-3">
      <button className="btn btn-primary btn-block w-100" type="submit">
        {title}
      </button>
    </div>
  );
}

export default Button;
