import React from "react";

type Props = {};

function FogotPassword({}: Props) {
  return (
    <div className="page-wrapper">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="index.html">
                    {/* <img className="img-fluid for-light" src="../assets/images/logo/logo2.png" alt="looginpage"/> */}
                  </a>
                </div>
                <div className="login-main">
                  <form className="theme-form">
                    <h4>Reset Your Password</h4>
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <div className="form-input position-relative">
                        <input className="form-control" type="text" />
                      </div>
                    </div>

                    <div className="form-group mb-0">
                      <button
                        className="btn btn-primary btn-block w-100 mt-3"
                        type="submit"
                      >
                        Submit{" "}
                      </button>
                    </div>
                    <p className="mt-4 mb-0">
                      Please click the link after submit.
                      <a className="ms-2" onClick={() => console.log("signin")}>
                        Back to login
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FogotPassword;
