import React from "react";

type Props = {
  header: string;
  desc: string;
};

function Title({ header, desc }: Props) {
  return (
    <React.Fragment>
      <h4 className="text-center">{header}</h4>
      <p className="text-center">{desc}</p>
    </React.Fragment>
  );
}

export default Title;
