import React from "react";
import Brand from "../brand/Brand";
import Title from "../title/Title";
import Input from "../input/Input";
import Remember from "../remember/Remember";
import Button from "../button/Button";
import Or from "../or/Or";
import Social from "../social/Social";
import FormFooter from "../form-footer/FormFooter";

type Props = {};

function Signin({}: Props) {
  const updatePageValue = (value: String) => {
    console.log(value);
  };
  return (
    <div className="login-main">
      <form className="theme-form">
        <Title
          header="Sign in to account"
          desc="Enter your email & password to login"
        />
        <Input
          title="Email Address"
          placeholder="Test@gmail.com"
          type="email"
          enableShowHide={false}
        />
        <Input
          title="Password"
          placeholder="*********"
          type="password"
          enableShowHide={true}
        />
        <Remember
          callback={() => console.log("called ")}
          checkboxTitle="Remember password"
        />
        <Button title="Signin" />
        <Or title="Or Sign in with" />
        <Social />
        <FormFooter callback={() => console.log("callback called")} />
      </form>
    </div>
  );
}

export default Signin;
