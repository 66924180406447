import React from "react";

type Props = {
  title: string;
  placeholder: string;
  type: string;
  enableShowHide: boolean;
};

function Input({ title, placeholder, type, enableShowHide }: Props) {
  return (
    <div className="form-group">
      <label className="col-form-label">{title}</label>
      {type === "password" && (
        <div className="form-input position-relative">
          <input
            className="form-control"
            type="password"
            name="login[password]"
            placeholder="*********"
          />
          {enableShowHide && (
            <div className="show-hide">
              <span className="show"></span>
            </div>
          )}
        </div>
      )}
      {type !== "password" && (
        <input
          className={`form-control
        }`}
          type={type}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}

export default Input;
