import React from "react";

type Props = {
  callback: Function;
};

function FormFooter({ callback }: Props) {
  return (
    <p className="mt-4 mb-0 text-center">
      Don't have account?
      <a className="ms-2" onClick={() => callback("signup")}>
        Create Account
      </a>
    </p>
  );
}

export default FormFooter;
