import React from "react";

type Props = {
  callback: Function;
  checkboxTitle: string;
};

function Remember({ callback, checkboxTitle }: Props) {
  return (
    <div className="form-group mb-0">
      <div className="checkbox p-0">
        <input id="checkbox1" type="checkbox" />
        <label className="text-muted" htmlFor="checkbox1">
          {checkboxTitle}
        </label>
      </div>
      <a className="link" onClick={() => callback("forgotpassword")}>
        Forgot password?
      </a>
    </div>
  );
}

export default Remember;
