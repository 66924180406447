import React from "react";

type Props = {};

const Social = (props: Props) => {
  return (
    <div className="form-group">
      <ul className="login-social">
        <li>
          <a href="https://www.linkedin.com" target="_blank">
            <i data-feather="linkedin"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com" target="_blank">
            <i data-feather="twitter"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com" target="_blank">
            <i data-feather="facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com" target="_blank">
            <i data-feather="instagram"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
